<template>
  <BaseModal name="modal-cadastro-manual" size="lg" title="Cadastro de Membros" @hidden="skip">
    <div class="spaceModal" data-anima="top">
      <div class="spaceSelectPainel">
        <b-form @submit.stop.prevent>
          <div class="spacePainels">
            <div class="spaceInputs">
              <b-form-group label="Nome do Membro" label-for="name" id="step4">
                <b-form-input name="nameUser" id="nameUser" v-model="name" v-validate="{ required: true }"
                  placeholder="Nome do Membro"></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">Nome do Membro é
                  necessario</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group label="E-mail do Membro" label-for="name" id="step4">
                <b-form-input name="nameUser" id="nameUser" type="email" v-model="email" v-validate="{ required: true }"
                  placeholder="E-mail do Membro"></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('email')">E-mail do Membro é
                  necessario</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group label="Telefone do Membro" label-for="name" id="step4">
                <b-form-input name="nameUser" id="nameUser" v-mask="['(##) ####-####', '(##) #####-####']"
                  v-model="cellphone" v-validate="{ required: true }" placeholder="Telefone do Membro"></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('cellphone')">Telefone do Membro é
                  necessario</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group label="CPF/CNPJ" label-for="name" id="step4">
                <b-form-input name="nameUser" id="nameUser" v-mask="['###.###.###-##', '##.###.###/####-##']"
                  v-model="cpf" v-validate="{ required: true }" placeholder="CPF/CNPJ"></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('cpf')">CPF/CNPJ é necessario</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group label="Tipo de Liberação" label-for="fonte">
                <b-form-select v-model="typeSelect" placeholder="Selecione uma Liberação"
                  :options="options"></b-form-select>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group v-if="typeSelect === 'course_class'" label="Selecione uma Turma" label-for="fonte">
                <multiselect data-anima="top" @open="openCourse" class="item" placeholder="Turmas" selectedLabel=""
                  deselectLabel="" tagPlaceholder="" selectLabel="" track-by="id" :multiple="false" :taggable="false"
                  :custom-label="customLabel" v-model="selectCourse" :options="AllClass">
                  <template slot="afterList">
                    <div v-observe-visibility="reachedEndOfList2" />
                  </template>
                </multiselect>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group v-if="typeSelect === 'signature'" label="Selecione uma Assinatura" label-for="fonte">
                <span>
                  <multiselect data-anima="top" @open="openSignature" placeholder="Assinaturas" selectedLabel=""
                    deselectLabel="" tagPlaceholder="" selectLabel="" track-by="id" :multiple="false" :taggable="false"
                    :custom-label="customLabel" v-model="selectAssing" :options="AllSignature">
                    <template slot="afterList">
                      <div v-observe-visibility="reachedEndOfList" />
                    </template>
                  </multiselect>
                </span>
              </b-form-group>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <template v-slot:footer="{ cancel }">
      <div class="header">
        <BaseButton class="mr-4" variant="link-info" @click="cancel">
          Cancelar
        </BaseButton>
        <BaseButton variant="black" :disabled="loading" class="skiped" @click="makeAccount">
          Cadastrar
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>
<script>
import ClassService from "@/services/resources/ClassService";
const serviceClass = ClassService.build();
import notify from "@/services/libs/notificacao";
import axios from "axios";
import Cookies from "js-cookie";
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
import Multiselect from "vue-multiselect";
import SignatureService from "@/services/resources/SignatureService";
const serviceSignature = SignatureService.build();

export default {
  components: { Multiselect },
  props: ["type"],
  data() {
    return {
      name: "",
      email: "",
      cellphone: "",
      cpf: "",
      selectCourse: [],
      pageActualClass: 1,
      pageActualSignature: 1,
      lastPageSignature: 1,
      AllSignature: [],
      lastPageClass: 1,
      AllClass: [],
      selectAssing: [],
      typeSelect: null,
      loading: false,
      options: [
        { value: null, text: "Selecione um tipo de Liberação" },
        { value: "course_class", text: "Turmas" },
        { value: "signature", text: "Assinaturas" },
      ],
    };
  },
  methods: {
    openSignature() {
      this.pageActualSignature = 1;
      this.AllSignature = [];
      this.getAllSignature();
    },
    reachedEndOfList(reached) {
      if (reached) {
        this.addNextPageAssing();
      }
    },
    addNextPageAssing() {
      if (this.lastPageSignature >= this.pageActualSignature) {
        this.pageActualSignature = this.pageActualSignature + 1;
        this.getAllSignature("push");
      }
    },
    getAllSignature(action) {
      this.$root.$emit("loadOn");
      if (this.pageActualSignature === null) {
        this.pageActualSignature = 1;
      }

      var url = "page=" + this.pageActualSignature;
      serviceSignature
        .search(url)
        .then((resp) => {
          //console.log("todas as assinaturas", resp);
          var data = resp.data;
          this.pageActualSignature = resp.current_page;
          this.lastPageSignature = resp.last_page;
          if (action === "push") {
            let list = resp.data;
            for (let i = 0; i < list.length; i++) {
              const element = list[i];
              this.AllSignature.push(element);
            }
          } else {
            this.AllSignature = data;
          }
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    customLabel({ title }) {
      return `${title}`;
    },
    reachedEndOfList2(reached) {
      if (reached) {
        this.addNextPageAssing2();
      }
    },
    addNextPageAssing2() {
      if (this.lastPageClass >= this.pageActualClass) {
        this.pageActualClass = this.pageActualClass + 1;
        this.getAllClass("push");
      }
    },
    openCourse() {
      this.pageActualClass = 1;
      this.pageActualCourse = 1;
      this.AllCopageActualCourse = [];
      this.AllClass = [];
      this.getAllClass();
    },
    getAllClass(action) {
      this.$root.$emit("loadOn");
      if (this.pageActualClass === null) {
        this.pageActualClass = 1;
      }
      var url = "page=" + this.pageActualClass;
      serviceClass
        .search(url)
        .then((resp) => {
          //console.log("todas as classes", resp);
          var data = resp.data;
          this.pageActualClass = resp.current_page;
          this.lastPageClass = resp.last_page;
          if (action === "push") {
            let list = resp.data;
            for (let i = 0; i < list.length; i++) {
              const element = list[i];
              this.AllClass.push(element);
            }
          } else {
            this.AllClass = data;
          }
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },

    makeAccount() {
      this.loading = true;

      if (this.typeSelect === null) {
        this.$bvToast.toast("Necessário selecionar um tipo de liberação!", {
          title: "Cadastro Manual!",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }
      let data = {
        id: "manual-registration",
        name: this.name,
        email: this.email,
        cellphone: this.cellphone,
        cpf_cnpj: this.cpf,
      };

      if (this.typeSelect === "course_class") {
        data.course_class_id = this.selectCourse.id;
        data.type = "course_class";
      } else {
        data.signature_id = this.selectAssing.id;
        data.type = "signature";
      }

      serviceMember
        .postID(data)
        .then((resp) => {
          if (resp.status === "updated") {
            this.$bvToast.toast("Membro Atualizado!", {
              title: "Cadastro Manual!",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          } else {
            this.$bvToast.toast("Membro Adicionado!", {
              title: "Cadastro Manual!",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
          this.$root.$emit(
            "bv::hide::modal",
            "modal-cadastro-manual",
            "#btnShow"
          );
          this.$forceUpdate();
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
        });
    },
    skip() {
      this.name = "";
      this.email = "";
      this.cellphone = "";
      this.cpf = "";
      this.selectCourse = [];
      this.pageActualClass = 1;
      this.pageActualSignature = 1;
      this.lastPageSignature = 1;
      this.AllSignature = [];
      this.lastPageClass = 1;
      this.AllClass = [];
      this.selectAssing = [];
      this.typeSelect = null;
      this.$emit("close");
    },
    saveProject() {
      var data = "";
      if (this.statusDemo) {
        data = {
          site_name: this.nameUser,
          site_description: this.descricaoProject,
          subdomain: this.slugProject,
          create_demo: true,
        };
      } else {
        data = {
          site_name: this.nameUser,
          site_description: this.descricaoProject,
          subdomain: this.slugProject,
        };
      }
      var that = this;
      this.$root.$emit("loadOn");
      axios
        .post(process.env.VUE_APP_API_HOST + `/site`, data, {
          headers: {
            Authorization: Cookies.get("memberAuth"),
          },
        })
        .then(function (resp) {
          //console.log("created project", resp);
          var newsite = resp.data;
          newsite.domains = [];
          newsite.custom_fields_filled = true;
          notify("sucesso", "Projeto criado com Sucesso!");
          setTimeout(() => {
            that.$root.$emit(
              "bv::hide::modal",
              "modal-novo-cadastro",
              "#btnShow"
            );
            that.$store.dispatch("auth/blogSelected", newsite);
          }, 3000);
          that.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err.response);
          var error = err.response.data;
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
          that.$root.$emit("loadOff");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
#modal-cadastro-manual {
  .modal-body {
    padding: 0 !important;
  }

  .multiselect__tags {
    border: 1px solid #d2d2d2 !important;
  }

  .multiselect__placeholder,
  .multiselect__single {
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #333 !important;
  }

  .multiselect__select {
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat !important;
  }

  .multiselect__select:before {
    border: none !important;
  }

  input.form-control.input-group.has-error {
    border: 1px solid #a94442 !important;
    box-shadow: inset 0 1px 1px #00000014, 0 0 6px #ce8483 !important;
  }

  input.form-control.input-group.has-success {
    border: 1px solid #2b542c !important;
    box-shadow: inset 0 1px 1px #00000014, 0 0 6px #67b168 !important;
  }

  .textSlug {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
    margin-top: 12px;
    margin-left: 4px;
  }

  .spaceInputs {
    margin-bottom: 30px;

    select {
      border: 1px solid #ededf0;
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #333;
      overflow: hidden !important;
      background: var(--backgroundcolor) !important;
    }

    input,
    textarea {
      border: 1px solid #ededf0;
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #333;
      overflow: hidden !important;
      resize: none !important;
      background: white;
    }

    input:hover,
    select:hover,
    textarea:hover,
    input:focus,
    select:focus,
    textarea:focus {
      border: 1px solid var(--maincolor);
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #333;
    }

    label {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor);
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .newPainel {
    background: var(--maincolor);
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 30px;
    height: 55px;
    width: 250px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #ffffff;
    padding: 0px 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .modal-header {
    border: none !important;
  }

  .spaceModal {
    padding: 5px ​30px 0px 30px;
    margin-top: -20px;

    .logout {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ff0c37;
      margin-top: 10px;
      cursor: pointer;
    }

    .logout2 {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--maincolor);
      margin-top: 10px;
      cursor: pointer;
    }

    .titleModal {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
    }

    .spaceSelectPainel {
      // background: var(--backgroundcolor);
      // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 30px;
      padding: 20px 0px 0px 0px;

      .lineDiv {
        height: 0.5px;
        background: #ededf0;
        width: 100%;
        margin-bottom: 25px;
        margin-top: 25px;
      }

      .flexData {
        display: flex;
        margin-bottom: 20px;
        cursor: pointer;

        .pointer {
          background: rgba(0, 214, 228, 0.1);
          border: 1px solid #00d6e4;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }

        .title {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: var(--fontcolor);
          margin-left: 20px;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
